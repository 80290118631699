// ************************* Font ****************************
// Font All **************************************************
.f {
  font-style: normal;
  font-weight: 400;

  // Font Italic *********************************************
  &-italic {
    font-style: italic;
  }

  // Font Display ********************************************
  &-display {
    &-lg {
      font-size: 3.5625rem;
      line-height: 4rem;
    }

    &-md {
      font-size: 2.8125rem;
      line-height: 3.25rem;
    }

    &-sm {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }
  }

  // Font Headline *******************************************
  &-headline {
    &-lg {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    &-md {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    &-sm {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  // Font Title **********************************************
  &-title {
    &-lg {
      font-size: 1.375rem;
      line-height: 1.75rem;
    }

    &-md {
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.00938rem;
    }

    &-sm {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.00625rem;
    }
  }

  // Font Label **********************************************
  &-label {
    &-lg {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.00625rem;
    }

    &-md {
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.00938rem;
    }

    &-sm {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.03125rem;
    }
  }

  // Font Body ***********************************************
  &-body {
    &-lg {
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.03125rem;
    }

    &-md {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.01563rem;
    }

    &-sm {
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.025rem;
    }
  }

  // Font Light **********************************************
  &-light {
    font-weight: 300;
  }

  // Font Regular ********************************************
  &-regular {
    font-weight: 400;
  }

  // Font Medium *********************************************
  &-medium {
    font-weight: 500;
  }

  // Font Semi Bold ******************************************
  &-semi {
    font-weight: 600;
  }

  // Font Bold ***********************************************
  &-bold {
    font-weight: 700;
  }
}
