$color-bases: primary, secondary, tertiary, success, error, info, warning,
  neutral, neutral-variant, dark;
$color-values: 10, 20, 30, 40, 50, 60, 70, 80, 90, 95;

// ******************************************** Color *********************************************

// Mixin for Color ********************************************************************************
@mixin color-variant($color-base, $color-value) {
  &-#{$color-value} {
    color: var(--color-#{$color-base}-#{$color-value});
  }
}

// Color All **************************************************************************************
.c {
  @each $color-base in $color-bases {
    &-#{$color-base} {
      color: var(--color-#{$color-base}-50);

      @each $color-value in $color-values {
        @include color-variant($color-base, $color-value);
      }
    }
  }
}

// ************************************* Background Color *****************************************

// Mixin for Background Color *********************************************************************
@mixin background-color-variant($color-base, $color-value) {
  &-#{$color-value} {
    background-color: var(--color-#{$color-base}-#{$color-value});
  }
}

// Background Color All ***************************************************************************
.bg {
  @each $color-base in $color-bases {
    &-#{$color-base} {
      background: var(--color-#{$color-base}-50);

      @each $color-value in $color-values {
        @include background-color-variant($color-base, $color-value);
      }
    }
  }
}
