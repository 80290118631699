:root {
  // ************************ Colors ***************************
  // Blue ******************************************************
  --color-blue-10: #00202d;
  --color-blue-20: #00405b;
  --color-blue-30: #005f88;
  --color-blue-40: #007fb6;
  --color-blue-50: #009fe3;
  --color-blue-60: #33b2e9;
  --color-blue-70: #66c5ee;
  --color-blue-80: #99d9f4;
  --color-blue-90: #ccecf9;
  --color-blue-95: #e6f5fc;
  // Blue Dark *************************************************
  --color-ocean-10: #001624;
  --color-ocean-20: #002c48;
  --color-ocean-30: #00436d;
  --color-ocean-40: #005991;
  --color-ocean-50: #006fb5;
  --color-ocean-60: #338cc4;
  --color-ocean-70: #66a9d3;
  --color-ocean-80: #99c5e1;
  --color-ocean-90: #cce2f0;
  --color-ocean-95: #e6f1f8;
  // Blue Light ************************************************
  --color-sky-10: #002633;
  --color-sky-20: #004c66;
  --color-sky-30: #007399;
  --color-sky-40: #0099cc;
  --color-sky-50: #00b9f8;
  --color-sky-60: #33ccff;
  --color-sky-70: #66d9ff;
  --color-sky-80: #99e5ff;
  --color-sky-90: #ccf2ff;
  --color-sky-95: #e6f9ff;
  // Purple ****************************************************
  --color-purple-10: #1d072b;
  --color-purple-20: #3a0e56;
  --color-purple-30: #561680;
  --color-purple-40: #731dab;
  --color-purple-50: #9024d6;
  --color-purple-60: #a650de;
  --color-purple-70: #bc7ce6;
  --color-purple-80: #d3a7ef;
  --color-purple-90: #e9d3f7;
  --color-purple-95: #f4e9fb;
  // Green *****************************************************
  --color-green-10: #062415;
  --color-green-20: #0c482b;
  --color-green-30: #126b40;
  --color-green-40: #188f56;
  --color-green-50: #1eb36b;
  --color-green-60: #4bc289;
  --color-green-70: #78d1a6;
  --color-green-80: #a5e1c4;
  --color-green-90: #d2f0e1;
  --color-green-95: #e9f7f0;
  // Red *******************************************************
  --color-red-10: #310d0b;
  --color-red-20: #621b16;
  --color-red-30: #922820;
  --color-red-40: #c3362b;
  --color-red-50: #f44336;
  --color-red-60: #f6695e;
  --color-red-70: #f88e86;
  --color-red-80: #fbb4af;
  --color-red-90: #fdd9d7;
  --color-red-95: #feeceb;
  // Yellow ****************************************************
  --color-yellow-10: #332500;
  --color-yellow-20: #664a00;
  --color-yellow-30: #996e00;
  --color-yellow-40: #cc9300;
  --color-yellow-50: #ffb800;
  --color-yellow-60: #ffc633;
  --color-yellow-70: #ffd466;
  --color-yellow-80: #ffe399;
  --color-yellow-90: #fff1cc;
  --color-yellow-95: #fff8e6;
  // Gray *******************************************************
  --color-gray-10: #1c1b1f;
  --color-gray-20: #313033;
  --color-gray-30: #484649;
  --color-gray-40: #605d62;
  --color-gray-50: #787579;
  --color-gray-60: #939094;
  --color-gray-70: #aeaaae;
  --color-gray-80: #c9c5ca;
  --color-gray-90: #d9d9d9;
  --color-gray-95: #f2f1f2;
  // Gray Purplish **********************************************
  --color-dove-10: #1d1a22;
  --color-dove-20: #322f37;
  --color-dove-30: #49454f;
  --color-dove-40: #605d66;
  --color-dove-50: #79747e;
  --color-dove-60: #938f99;
  --color-dove-70: #aea9b4;
  --color-dove-80: #cac4d0;
  --color-dove-90: #e7e0ec;
  --color-dove-95: #f5eefa;
  // Gray Dark **************************************************
  --color-raven-10: #040405;
  --color-raven-20: #08070a;
  --color-raven-30: #0c0b0e;
  --color-raven-40: #100e13;
  --color-raven-50: #141218;
  --color-raven-60: #434146;
  --color-raven-70: #727174;
  --color-raven-80: #a1a0a3;
  --color-raven-90: #d0d0d1;
  --color-raven-95: #e8e7e8;
  // Base *******************************************************
  --color-base-white: #ffffff;
  --color-base-black: #000000;
  --color-background-10: #f1f1f1;
  --color-background-20: var(--color-blue-50);
  /*primary 50-30*/
  --color-state-light: rgba(255, 255, 255, 0.12);

  // ************************ Brand *****************************
  // Primary ****************************************************
  --color-primary-10: var(--color-blue-10);
  --color-primary-20: var(--color-blue-20);
  --color-primary-30: var(--color-blue-30);
  --color-primary-40: var(--color-blue-40);
  --color-primary-50: var(--color-blue-50);
  --color-primary-60: var(--color-blue-60);
  --color-primary-70: var(--color-blue-70);
  --color-primary-80: var(--color-blue-80);
  --color-primary-90: var(--color-blue-90);
  --color-primary-95: var(--color-blue-95);
  // Secondary **************************************************
  --color-secondary-10: var(--color-ocean-10);
  --color-secondary-20: var(--color-ocean-20);
  --color-secondary-30: var(--color-ocean-30);
  --color-secondary-40: var(--color-ocean-40);
  --color-secondary-50: var(--color-ocean-50);
  --color-secondary-60: var(--color-ocean-60);
  --color-secondary-70: var(--color-ocean-70);
  --color-secondary-80: var(--color-ocean-80);
  --color-secondary-90: var(--color-ocean-90);
  --color-secondary-95: var(--color-ocean-95);
  // Tertiary ***************************************************
  --color-tertiary-10: var(--color-purple-10);
  --color-tertiary-20: var(--color-purple-20);
  --color-tertiary-30: var(--color-purple-30);
  --color-tertiary-40: var(--color-purple-40);
  --color-tertiary-50: var(--color-purple-50);
  --color-tertiary-60: var(--color-purple-60);
  --color-tertiary-70: var(--color-purple-70);
  --color-tertiary-80: var(--color-purple-80);
  --color-tertiary-90: var(--color-purple-90);
  --color-tertiary-95: var(--color-purple-95);
  // Success ****************************************************
  --color-success-10: var(--color-green-10);
  --color-success-20: var(--color-green-20);
  --color-success-30: var(--color-green-30);
  --color-success-40: var(--color-green-40);
  --color-success-50: var(--color-green-50);
  --color-success-60: var(--color-green-60);
  --color-success-70: var(--color-green-70);
  --color-success-80: var(--color-green-80);
  --color-success-90: var(--color-green-90);
  --color-success-95: var(--color-green-95);
  // Error *******************************************************
  --color-error-10: var(--color-red-10);
  --color-error-20: var(--color-red-20);
  --color-error-30: var(--color-red-30);
  --color-error-40: var(--color-red-40);
  --color-error-50: var(--color-red-50);
  --color-error-60: var(--color-red-60);
  --color-error-70: var(--color-red-70);
  --color-error-80: var(--color-red-80);
  --color-error-90: var(--color-red-90);
  --color-error-95: var(--color-red-95);
  // Info ********************************************************
  --color-info-10: var(--color-sky-10);
  --color-info-20: var(--color-sky-20);
  --color-info-30: var(--color-sky-30);
  --color-info-40: var(--color-sky-40);
  --color-info-50: var(--color-sky-50);
  --color-info-60: var(--color-sky-60);
  --color-info-70: var(--color-sky-70);
  --color-info-80: var(--color-sky-80);
  --color-info-90: var(--color-sky-90);
  --color-info-95: var(--color-sky-95);
  // Warning ****************************************************
  --color-warning-10: var(--color-yellow-10);
  --color-warning-20: var(--color-yellow-20);
  --color-warning-30: var(--color-yellow-30);
  --color-warning-40: var(--color-yellow-40);
  --color-warning-50: var(--color-yellow-50);
  --color-warning-60: var(--color-yellow-60);
  --color-warning-70: var(--color-yellow-70);
  --color-warning-80: var(--color-yellow-80);
  --color-warning-90: var(--color-yellow-90);
  --color-warning-95: var(--color-yellow-95);
  // Neutral ****************************************************
  --color-neutral-10: var(--color-gray-10);
  --color-neutral-20: var(--color-gray-20);
  --color-neutral-30: var(--color-gray-30);
  --color-neutral-40: var(--color-gray-40);
  --color-neutral-50: var(--color-gray-50);
  --color-neutral-60: var(--color-gray-60);
  --color-neutral-70: var(--color-gray-70);
  --color-neutral-80: var(--color-gray-80);
  --color-neutral-90: var(--color-gray-90);
  --color-neutral-95: var(--color-gray-95);
  // Neutral Variant *********************************************
  --color-neutral-variant-10: var(--color-dove-10);
  --color-neutral-variant-20: var(--color-dove-20);
  --color-neutral-variant-30: var(--color-dove-30);
  --color-neutral-variant-40: var(--color-dove-40);
  --color-neutral-variant-50: var(--color-dove-50);
  --color-neutral-variant-60: var(--color-dove-60);
  --color-neutral-variant-70: var(--color-dove-70);
  --color-neutral-variant-80: var(--color-dove-80);
  --color-neutral-variant-90: var(--color-dove-90);
  --color-neutral-variant-95: var(--color-dove-95);
  // Dark *******************************************************
  --color-dark-10: var(--color-raven-10);
  --color-dark-20: var(--color-raven-20);
  --color-dark-30: var(--color-raven-30);
  --color-dark-40: var(--color-raven-40);
  --color-dark-50: var(--color-raven-50);
  --color-dark-60: var(--color-raven-60);
  --color-dark-70: var(--color-raven-70);
  --color-dark-80: var(--color-raven-80);
  --color-dark-90: var(--color-raven-90);
  --color-dark-95: var(--color-raven-95);
  // Background *************************************************
  --background-color: var(--color-background-10);

  // ************************ Font Family ***********************
  --font-family-gantari: "Gantari", sans-serif;

  // ************************ Font Rem **************************
  --font-size-rem: 16px;

  // ************************ Spacing ***************************
  --spacing-0: 0;
  --spacing-1: 0.125rem;
  --spacing-2: 0.25rem;
  --spacing-3: 0.5rem;
  --spacing-4: 0.75rem;
  --spacing-5: 1rem;
  --spacing-6: 1.5rem;
  --spacing-7: 2rem;
  --spacing-8: 2.5rem;
  --spacing-9: 3rem;
  --spacing-10: 3.5rem;
  --spacing-11: 4rem;
  --spacing-12: 5rem;
  --spacing-13: 6rem;
  --spacing-14: 10rem;
  --spacing-100: 100%;

  // ************************ Navbar e Sidebar ******************
  --side-width-min: 3.625rem;
  --side-width-max: 14.8465rem;
  --navbar-height-min: 5rem;
  --navbar-height-max: 5rem;
  --nav-transition-time: 0.4s;

  // ************************ Media Query ***********************
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}
