// ************************* Padding *************************
// Padding All ***********************************************
.p {
  &-0 {
    padding: var(--spacing-0);
  }

  &-1 {
    padding: var(--spacing-1);
  }

  &-2 {
    padding: var(--spacing-2);
  }

  &-3 {
    padding: var(--spacing-3);
  }

  &-4 {
    padding: var(--spacing-4);
  }

  &-5 {
    padding: var(--spacing-5);
  }
  &-6 {

    padding: var(--spacing-6);
  }
  &-7 {
    padding: var(--spacing-7);
  }

  &-8 {
    padding: var(--spacing-8);
  }

  &-9 {
    padding: var(--spacing-9);
  }

  &-10 {
    padding: var(--spacing-10);
  }

  &-11 {
    padding: var(--spacing-11);
  }

  &-12 {
    padding: var(--spacing-12);
  }

  &-13 {
    padding: var(--spacing-13);
  }

  &-14 {
    padding: var(--spacing-14);
  }

  // Padding Top or Padding Y **********************************
  &t,
  &y {
    &-0 {
      padding-top: var(--spacing-0);
    }

    &-1 {
      padding-top: var(--spacing-1);
    }

    &-2 {
      padding-top: var(--spacing-2);
    }

    &-3 {
      padding-top: var(--spacing-3);
    }

    &-4 {
      padding-top: var(--spacing-4);
    }

    &-5 {
      padding-top: var(--spacing-5);
    }

    &-6 {
      padding-top: var(--spacing-6);
    }

    &-7 {
      padding-top: var(--spacing-7);
    }

    &-8 {
      padding-top: var(--spacing-8);
    }

    &-9 {
      padding-top: var(--spacing-9);
    }

    &-10 {
      padding-top: var(--spacing-10);
    }

    &-11 {
      padding-top: var(--spacing-11);
    }

    &-12 {
      padding-top: var(--spacing-12);
    }

    &-13 {
      padding-top: var(--spacing-13);
    }

    &-14 {
      padding-top: var(--spacing-14);
    }
  }

  // Padding End or Padding X **********************************
  &e,
  &r,
  &x {
    &-0 {
      padding-right: var(--spacing-0);
    }

    &-1 {
      padding-right: var(--spacing-1);
    }

    &-2 {
      padding-right: var(--spacing-2);
    }

    &-3 {
      padding-right: var(--spacing-3);
    }

    &-4 {
      padding-right: var(--spacing-4);
    }

    &-5 {
      padding-right: var(--spacing-5);
    }

    &-6 {
      padding-right: var(--spacing-6);
    }

    &-7 {
      padding-right: var(--spacing-7);
    }

    &-8 {
      padding-right: var(--spacing-8);
    }

    &-9 {
      padding-right: var(--spacing-9);
    }

    &-10 {
      padding-right: var(--spacing-10);
    }

    &-11 {
      padding-right: var(--spacing-11);
    }

    &-12 {
      padding-right: var(--spacing-12);
    }

    &-13 {
      padding-right: var(--spacing-13);
    }

    &-14 {
      padding-right: var(--spacing-14);
    }
  }

  // Padding Bottom or Padding Y *******************************
  &b,
  &y {
    &-0 {
      padding-bottom: var(--spacing-0);
    }

    &-1 {
      padding-bottom: var(--spacing-1);
    }

    &-2 {
      padding-bottom: var(--spacing-2);
    }

    &-3 {
      padding-bottom: var(--spacing-3);
    }

    &-4 {
      padding-bottom: var(--spacing-4);
    }

    &-5 {
      padding-bottom: var(--spacing-5);
    }

    &-6 {
      padding-bottom: var(--spacing-6);
    }

    &-7 {
      padding-bottom: var(--spacing-7);
    }

    &-8 {
      padding-bottom: var(--spacing-8);
    }

    &-9 {
      padding-bottom: var(--spacing-9);
    }

    &-10 {
      padding-bottom: var(--spacing-10);
    }

    &-11 {
      padding-bottom: var(--spacing-11);
    }

    &-12 {
      padding-bottom: var(--spacing-12);
    }

    &-13 {
      padding-bottom: var(--spacing-13);
    }

    &-14 {
      padding-bottom: var(--spacing-14);
    }
  }

  // Padding Start or Padding X ********************************
  &s,
  &l,
  &x {
    &-0 {
      padding-left: var(--spacing-0);
    }

    &-1 {
      padding-left: var(--spacing-1);
    }

    &-2 {
      padding-left: var(--spacing-2);
    }

    &-3 {
      padding-left: var(--spacing-3);
    }

    &-4 {
      padding-left: var(--spacing-4);
    }

    &-5 {
      padding-left: var(--spacing-5);
    }

    &-6 {
      padding-left: var(--spacing-6);
    }

    &-7 {
      padding-left: var(--spacing-7);
    }

    &-8 {
      padding-left: var(--spacing-8);
    }

    &-9 {
      padding-left: var(--spacing-9);
    }

    &-10 {
      padding-left: var(--spacing-10);
    }

    &-11 {
      padding-left: var(--spacing-11);
    }

    &-12 {
      padding-left: var(--spacing-12);
    }

    &-13 {
      padding-left: var(--spacing-13);
    }

    &-14 {
      padding-left: var(--spacing-14);
    }
  }
}

// ************************* Margin **************************
// Margin All ************************************************
.m {
  &-0 {
    margin: var(--spacing-0);
  }

  &-1 {
    margin: var(--spacing-1);
  }

  &-2 {
    margin: var(--spacing-2);
  }

  &-3 {
    margin: var(--spacing-3);
  }

  &-4 {
    margin: var(--spacing-4);
  }

  &-5 {
    margin: var(--spacing-5);
  }
  &-6 {
    margin: var(--spacing-6);

  }

 &-7 {
    margin: var(--spacing-7);
  }

  &-8 {
    margin: var(--spacing-8);
  }

  &-9 {
    margin: var(--spacing-9);
  }

  &-10 {
    margin: var(--spacing-10);
  }

  &-11 {
    margin: var(--spacing-11);
  }

  &-12 {
    margin: var(--spacing-12);
  }

  &-13 {
    margin: var(--spacing-13);
  }

  &-14 {
    margin: var(--spacing-14);
  }

  // margin top or margin y
  &t,
  &y {
    &-0 {
      margin-top: var(--spacing-0);
    }

    &-1 {
      margin-top: var(--spacing-1);
    }

    &-2 {
      margin-top: var(--spacing-2);
    }

    &-3 {
      margin-top: var(--spacing-3);
    }

    &-4 {
      margin-top: var(--spacing-4);
    }

    &-5 {
      margin-top: var(--spacing-5);
    }

    &-6 {
      margin-top: var(--spacing-6);
    }

    &-7 {
      margin-top: var(--spacing-7);
    }

    &-8 {
      margin-top: var(--spacing-8);
    }

    &-9 {
      margin-top: var(--spacing-9);
    }

    &-10 {
      margin-top: var(--spacing-10);
    }

    &-11 {
      margin-top: var(--spacing-11);
    }

    &-12 {
      margin-top: var(--spacing-12);
    }

    &-13 {
      margin-top: var(--spacing-13);
    }

    &-14 {
      margin-top: var(--spacing-14);
    }
  }

  // margin end or margin x
  &e,
  &r,
  &x {
    &-0 {
      margin-right: var(--spacing-0);
    }

    &-1 {
      margin-right: var(--spacing-1);
    }

    &-2 {
      margin-right: var(--spacing-2);
    }

    &-3 {
      margin-right: var(--spacing-3);
    }

    &-4 {
      margin-right: var(--spacing-4);
    }

    &-5 {
      margin-right: var(--spacing-5);
    }

    &-6 {
      margin-right: var(--spacing-6);
    }

    &-7 {
      margin-right: var(--spacing-7);
    }

    &-8 {
      margin-right: var(--spacing-8);
    }

    &-9 {
      margin-right: var(--spacing-9);
    }

    &-10 {
      margin-right: var(--spacing-10);
    }

    &-11 {
      margin-right: var(--spacing-11);
    }

    &-12 {
      margin-right: var(--spacing-12);
    }

    &-13 {
      margin-right: var(--spacing-13);
    }

    &-14 {
      margin-right: var(--spacing-14);
    }
  }

  // margin bottom or margin y
  &b,
  &y {
    &-0 {
      margin-bottom: var(--spacing-0);
    }

    &-1 {
      margin-bottom: var(--spacing-1);
    }

    &-2 {
      margin-bottom: var(--spacing-2);
    }

    &-3 {
      margin-bottom: var(--spacing-3);
    }

    &-4 {
      margin-bottom: var(--spacing-4);
    }

    &-5 {
      margin-bottom: var(--spacing-5);
    }

    &-6 {
      margin-bottom: var(--spacing-6);
    }

    &-7 {
      margin-bottom: var(--spacing-7);
    }

    &-8 {
      margin-bottom: var(--spacing-8);
    }

    &-9 {
      margin-bottom: var(--spacing-9);
    }

    &-10 {
      margin-bottom: var(--spacing-10);
    }

    &-11 {
      margin-bottom: var(--spacing-11);
    }

    &-12 {
      margin-bottom: var(--spacing-12);
    }

    &-13 {
      margin-bottom: var(--spacing-13);
    }

    &-14 {
      margin-bottom: var(--spacing-14);
    }
  }

  // margin start or margin x
  &s,
  &l,
  &x {
    &-0 {
      margin-left: var(--spacing-0);
    }

    &-1 {
      margin-left: var(--spacing-1);
    }

    &-2 {
      margin-left: var(--spacing-2);
    }

    &-3 {
      margin-left: var(--spacing-3);
    }

    &-4 {
      margin-left: var(--spacing-4);
    }

    &-5 {
      margin-left: var(--spacing-5);
    }

    &-6 {
      margin-left: var(--spacing-6);
    }

    &-7 {
      margin-left: var(--spacing-7);
    }

    &-8 {
      margin-left: var(--spacing-8);
    }

    &-9 {
      margin-left: var(--spacing-9);
    }

    &-10 {
      margin-left: var(--spacing-10);
    }

    &-11 {
      margin-left: var(--spacing-11);
    }

    &-12 {
      margin-left: var(--spacing-12);
    }

    &-13 {
      margin-left: var(--spacing-13);
    }

    &-14 {
      margin-left: var(--spacing-14);
    }
  }
}

// ************************* Gap *****************************
// Gap All ***************************************************
.g {
  &-0 {
    gap: var(--spacing-0);
  }

  &-1 {
    gap: var(--spacing-1);
  }

  &-2 {
    gap: var(--spacing-2);
  }

  &-3 {
    gap: var(--spacing-3);
  }

  &-4 {
    gap: var(--spacing-4);
  }

  &-5 {
    gap: var(--spacing-5);
  }

  &-6 {
    gap: var(--spacing-6);
  }

  &-7 {
    gap: var(--spacing-7);
  }

  &-8 {
    gap: var(--spacing-8);
  }

  &-9 {
    gap: var(--spacing-9);
  }

  &-10 {
    gap: var(--spacing-10);
  }

  &-11 {
    gap: var(--spacing-11);
  }

  &-12 {
    gap: var(--spacing-12);
  }

  &-13 {
    gap: var(--spacing-13);
  }

  &-14 {
    gap: var(--spacing-14);
  }

  // Gap Row *************************************************
  &r {
    &-0 {
      row-gap: var(--spacing-0);
    }

    &-1 {
      row-gap: var(--spacing-1);
    }

    &-2 {
      row-gap: var(--spacing-2);
    }

    &-3 {
      row-gap: var(--spacing-3);
    }

    &-4 {
      row-gap: var(--spacing-4);
    }

    &-5 {
      row-gap: var(--spacing-5);
    }

    &-6 {
      row-gap: var(--spacing-5);
   }

    &-7 {
      row-gap: var(--spacing-7);
    }

    &-8 {
      row-gap: var(--spacing-8);
    }

    &-9 {
      row-gap: var(--spacing-9);
    }

    &-10 {
      row-gap: var(--spacing-10);
    }

    &-11 {
      row-gap: var(--spacing-11);
    }

    &-12 {
      row-gap: var(--spacing-12);
    }

    &-13 {
      row-gap: var(--spacing-13);
    }

    &-14 {
      row-gap: var(--spacing-14);
    }
  }

  // Gap Column **********************************************
  &c {
    &-0 {
      column-gap: var(--spacing-0);
    }

    &-1 {
      column-gap: var(--spacing-1);
    }

    &-2 {
      column-gap: var(--spacing-2);
    }

    &-3 {
      column-gap: var(--spacing-3);
    }

    &-4 {
      column-gap: var(--spacing-4);
    }

    &-5 {
      column-gap: var(--spacing-5);
    }

    &-6 {
      column-gap: var(--spacing-5);
    }

    &-7 {
      column-gap: var(--spacing-7);
    }

    &-8 {
      column-gap: var(--spacing-8);
    }

    &-9 {
      column-gap: var(--spacing-9);
    }

    &-10 {
      column-gap: var(--spacing-10);
    }

    &-11 {
      column-gap: var(--spacing-11);
    }

    &-12 {
      column-gap: var(--spacing-12);
    }

    &-13 {
      column-gap: var(--spacing-13);
    }

    &-14 {
      column-gap: var(--spacing-14);
    }
  }
}
