// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@include mat.core();

//Available color palettes: https://material.io/design/color/
$app-backoffice-sintegra-primary: mat.define-palette((50: var(--color-primary-50),
      100: var(--color-primary-10),
      200: var(--color-primary-20),
      300: var(--color-primary-30),
      400: var(--color-primary-40),
      500: var(--color-primary-50),
      600: var(--color-primary-60),
      700: var(--color-primary-70),
      800: var(--color-primary-80),
      900: var(--color-primary-90),
      contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: rgba(black, 0.87),
        600: rgba(black, 0.87),
        700: rgba(black, 0.87),
        800: rgba(black, 0.87),
        900: white)));

$app-backoffice-sintegra-accent: mat.define-palette((50: var(--color-secondary-50),
      100: var(--color-secondary-10),
      200: var(--color-secondary-20),
      300: var(--color-secondary-30),
      400: var(--color-secondary-40),
      500: var(--color-secondary-50),
      600: var(--color-secondary-60),
      700: var(--color-secondary-70),
      800: var(--color-secondary-80),
      900: var(--color-secondary-90),
      contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: rgba(black, 0.87),
        600: rgba(black, 0.87),
        700: rgba(black, 0.87),
        800: rgba(black, 0.87),
        900: white)));

$app-backoffice-sintegra-warn: mat.define-palette((50: var(--color-red-50),
      100: var(--color-red-10),
      200: var(--color-red-20),
      300: var(--color-red-30),
      400: var(--color-red-40),
      500: var(--color-red-50),
      600: var(--color-red-60),
      700: var(--color-red-70),
      800: var(--color-red-80),
      900: var(--color-red-90),
      contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: rgba(black, 0.87),
        600: rgba(black, 0.87),
        700: rgba(black, 0.87),
        800: rgba(black, 0.87),
        900: white)));

$app-backoffice-sintegra-theme: mat.define-light-theme((color: (primary: $app-backoffice-sintegra-primary,
        accent: $app-backoffice-sintegra-accent,
        warn: $app-backoffice-sintegra-warn,
      )));

@include mat.all-component-themes($app-backoffice-sintegra-theme);

* {
  border: 0;
  margin: 0;
  padding: 0;
  font-family: var(--font-family-gantari) !important;
  box-sizing: border-box;
}

span .material-symbols-outlined,
mat-icon {
  font-family: 'Material Symbols Outlined' !important;
}

html,
body {
  // width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  font-size: var(--font-size-rem);
  /*spinner stroke color variable*/
  --mdc-circular-progress-active-indicator-color: var(--color-neutral-90);
}

.custom {
  width: 592px !important;
  height: 662px !important;
  top: '10%';
  left: '30%';
}

button {
  border-radius: 8px;
  cursor: pointer;
}

// button.custom-disable:disabled {
//   opacity: 0.4;
//   // background-color: gray;
// }

button[disabled] {
  opacity: 0.4;
}

p,
input {
  margin: 0 !important;
  --mdc-filled-text-field-input-text-color: var(--color-neutral-30, #484649);
  --mat-select-placeholder-text-color: var(--color-neutral-30, #484649);
}

.optional {
  padding-left: 2px;
}

.alert {
  color: var(--color-error-50);
  font-size: 12px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: var(--spacing-6) !important;
}

.ql-snow .ql-tooltip {
  z-index: 2;
  transform: none;
  left: 0 !important;
  top: 0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  max-height: unset;
}

/*Available language tabs inside object modal from table override*/
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  --mat-menu-item-label-text-size: 14px;
}

/*Language tabs styles override*/
.mat-mdc-tab {
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.1px;
  --mat-tab-header-label-text-line-height: 20px;
  --mat-tab-header-label-text-weight: 700;
}

.mat-mdc-form-field {
  --mdc-filled-text-field-container-color: transparent;
}

.clean-input {
  border-bottom: 1px solid var(--color-neutral-90);
}

.label {
  color: var(--color-neutral-50, #787579);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.error-msg {
  color: var(--color-error-50);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.error-button {
  background-color: transparent;
  outline: 1px solid var(--color-error-50);

  span {
    color: var(--color-error-50) !important;
  }
}

.mat-mdc-snack-bar-container {
  padding: 10px;
  --mdc-snackbar-container-color: #FFFFFF;
  --mdc-snackbar-supporting-text-color: var(--color-neutral-40);
  --mdc-snackbar-container-shape: 8px;
}

@media all and (max-width: 1200px) {
  .custom {
    width: 100% !important;
    height: 100% !important;
    min-width: 100% !important;
    min-height: 100% !important;
    max-width: unset;
    max-height: unset;
    top: 0;
    left: 0;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 0 !important;
  }

  .tab-add-button {
    padding-left: 50px;
  }
}

@media all and (max-width: 900px) {
  .tab-add-button {
    padding-left: 10px;
  }
}

@media all and (max-width: 600px) {
  .tab-add-button {
    padding-left: 0;
    margin-left: -10px;
  }
}
