$spacing-values: 0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14;

// ******************************************** Border ********************************************

// Mixin for Border Width *************************************************************************
@mixin border-width($position, $width) {
  @if $position == "all" {
    border-width: var(--spacing-#{$width});
  } @else {
    border-#{$position}-width: var(--spacing-#{$width});
  }
}

// Border All *************************************************************************************
.border {
  @each $value in $spacing-values {
                    &-#{$value} { @include border-width(all, $value); }
    
    // Border Top or Border Y *********************************************************************
    &-t, &-y      { &-#{$value} { @include border-width(top, $value); } }
    
    // Border End or Border X *********************************************************************
    &-e, &-x, &-r { &-#{$value} { @include border-width(right, $value); } }
    
    // Border Bottom or Border Y ******************************************************************
    &-b, &-y      { &-#{$value} { @include border-width(bottom, $value); } }
    
    // Border Start or Border X *******************************************************************
    &-s, &-x, &-l { &-#{$value} { @include border-width(left, $value); } }
  }
}
  
// Mixin for Border Radius ************************************************************************
@mixin border-radius($position, $radius) {
  @if $position == "all" {
    border-radius: var(--spacing-#{$radius});
  } @else {
    border-#{$position}-radius: var(--spacing-#{$radius});
  }
}
  
// Border Radius All ******************************************************************************
.rounded {
  @each $value in $spacing-values {
                                 &-#{$value} { @include border-radius(all, $value); }

    // Border Radius Top or Border Radius Start or Border Radius Top Start ************************
    &-t, &-s, &-l, &-ts, &-tl  { &-#{$value} { @include border-radius(top-left, $value); } }

    // Border Radius Top or Border Radius End or Border Radius Top End ****************************
    &-t, &-e, &-r, &-te, &-tr  { &-#{$value} { @include border-radius(top-right, $value); } }

    // Border Radius Bottom or Border Radius Start or Border Radius Bottom Start ******************
    &-b, &-s, &-l, &-bs, &-bl  { &-#{$value} { @include border-radius(bottom-left, $value); } }

    // Border Radius Bottom or Border Radius End or Border Radius Bottom End **********************
    &-b, &-e, &-r, &-be, &-br  { &-#{$value} { @include border-radius(bottom-right, $value); } }
  }

  &-full { @include border-radius(all, 100); }

  // Border Radius Top Full or Border Radius Start Full or Border Radius Top Start Full ***********
  &-t, &-s, &-l, &-ts, &-tl  { &-full  { @include border-radius(top-left, 100); } }

  // Border Radius Top Full or Border Radius End Full or Border Radius Top End Full ***************
  &-t, &-e, &-r, &-te, &-tr  { &-full  { @include border-radius(top-right, 100); } }

  // Border Radius Bottom Full or Border Radius Start Full or Border Radius Bottom Start Full *****
  &-b, &-s, &-l, &-bs, &-bl  { &-full  { @include border-radius(bottom-left, 100); } }

  // Border Radius Bottom or Border Radius End or Border Radius Bottom End ************************
  &-b, &-e, &-r, &-be, &-br  { &-full  { @include border-radius(bottom-right, 100); } }
}
